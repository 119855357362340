<template>
  <div>
    <el-tabs>
      <el-tab-pane label="certificate">
        <el-button type="primary" @click="exportPDF('showTotal')" size="medium"
          >Download the certificate</el-button
        >

       

        <div id="showTotal">
          <div
            v-for="(item, index) in total_awards"
            :key="index"
            class="certContainerContact"
          >
            <img v-if="item.project_code == '2024AM_Continental'" src="../../assets/image/cer_2024AM_Continental.jpg" class="model" />
            <img v-if="item.project_code == '2024IBO'" src="../../assets/image/cer_2024IBO.jpg" class="model" />
            <img v-if="item.project_code == '2024IPsyO'" src="../../assets/image/cer_ipsyo.jpg" class="model" />

            <div class="award">{{ item.award_lable }}</div>

            <div class="student">
              <span class="name">{{
                
                  item.contact_name
              }}</span>
            </div>
            <div class="school">{{ item.account_name }}</div>


            <div class="showAdviser">{{ showStr == '1' ? 'Adviser' : 'Leading Adviser' }}</div>


          </div>
        </div>

      </el-tab-pane>
     
    </el-tabs>
  </div>
</template>

<script>
import {
  getAccountContactCertificate
} from "../../api/eae";
import { getAccountId, getUserId, ENTRY_TYPES } from "../../utils/store";


export default {
  name: "awardfbla",
  data() {
    return {
      project_code: "",
      project_name: "",
      total_awards: [],
      event_awards: [],
      completes: [],
      transcripts: [],
      type: "all",
      id: "",
      award_type_label_arr: {
        "personal total": "Individual Total Score Rank",
        "event rank": "Individual Event Rank",
        "event team rank": "Team Event Rank",
      },
    };
  },
  mounted() {
    this.showStr = this.$route.query.show;
    this.fetchData();
    // this.getProjectName();
  },
  methods: {
    // getProjectName() {
    //   getProjectName(this.project_code).then((res) => {
    //     this.project_name = res.data.data;
    //   });
    // },
    fetchData() {
     
        getAccountContactCertificate(getUserId()).then((res) => {

          if (res.data.code === 0) {
            this.total_awards = res.data.data
          }
        });
      
    },
    
    exportPDF(id) {
      var style = document.createElement("style");

      if (id == "showTotal") {
        // style.innerHTML = "@page{size:landscape 1528px 1080px;}";
        style.innerHTML = "@page{size:A4 landscape;}";
      } else {
        // style.innerHTML = "@page{size:portrait 2480px 3508px;margin: auto 0mm}";
        style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
        document.getElementsByTagName("body")[0].style.zoom = 1;
      }

      window.document.head.appendChild(style);

      const printData = document.getElementById(id).innerHTML;

      this.PageSetup_Null(); //把页眉页脚设置为空

      window.document.body.innerHTML = printData; // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
      window.print(); // 开始打印

      //   const printData = document.getElementById(id).innerHTML

      //   window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
      //   window.print() // 开始打印
    },
    PageSetup_Null() {
      var HKEY_Root, HKEY_Path, HKEY_Key;

      HKEY_Root = "HKEY_CURRENT_USER";

      HKEY_Path = "\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

      try {
        var Wsh = new ActiveXObject("WScript.Shell");

        HKEY_Key = "header";

        Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");

        HKEY_Key = "footer";

        Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");
      } catch (e) {}
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "aleo-bold";
  src: url("../../assets/font/aleo-bold.ttf");
}
@font-face {
  font-family: "Aleo-Bold-2";
  src: url("../../assets/font/Aleo-Bold-2.otf");
}
@font-face {
  font-family: "Aleo-Italic-4";
  src: url("../../assets/font/Aleo-Italic-4.otf");
}
@font-face {
  font-family: "Aleo-Light-5";
  src: url("../../assets/font/Aleo-Light-5.otf");
}
@font-face {
  font-family: "Aleo-Regular-7";
  src: url("../../assets/font/Aleo-Regular-7.otf");
}
@font-face {
  font-family: "Bell-MT";
  src: url("../../assets/font/Bell-MT.ttf");
}

@font-face {
  font-family: "BellMT-Bold";
  src: url("../../assets/font/BellMT-Bold.otf");
}


.certContainerContact {
  position: relative;
  color: black;
 
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 430px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  
  .student {
    position: absolute;
    top: 325px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  
  .school {
    position: absolute;
    top: 385px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .showAdviser{
    position: absolute;
    font-size: 28px;
    top: 525px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  
}
.certContainer13 {
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 393px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;
  }
  .ziZm {
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm {
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }

  .award_team {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .award_team_out {
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .student_team_out {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school_team_out {
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_out_team {
    width: 297mm;
    position: absolute;
    top: 466px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .projectName {
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh {
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab {
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab {
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_rank {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_team {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .email {
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 345px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_rank {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .info_rank {
    position: absolute;
    top: 390px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
  }
  .school_team {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg {
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  red;
  }
}
.transcriptContainer {
  position: relative;
  margin-bottom: -4px;
  .model_p {
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name {
    position: absolute;
    top: 326px;
    left: 38px;
    font-size: 30px;
  }
  .info {
    position: absolute;
    top: 384px;
    left: 38px;
    font-size: 14px;
    .tag {
      font-family: "Aleo-Bold-2";
    }
    .content {
      font-family: "Aleo-Regular-7";
    }
  }
  .score {
    position: absolute;
    top: 440px;
    width: 640px;

    left: 55px;
    font-size: 12px;
    font-family: "Aleo-Regular-7";
    span {
      font-family: "Aleo-Bold-2";
      font-size: 14px;
    }
    .title {
      font-family: "aleo-bold";
    }
  }
  .score1 {
    position: absolute;
    top: 500px;
    width: 320px;
    left: 434px;
    font-size: 12px;
    font-family: "Aleo-Regular-7";
    span {
      font-family: "Aleo-Bold-2";
      font-size: 14px;
    }
    .title {
      font-family: "aleo-bold";
    }
  }
  .time1 {
    position: absolute;
    top: 890px;
    width: 320px;
    left: 40px;
    font-size: 18px;
    font-weight: 600;
    color: #2a358a;
    // font-family: 'Aleo-Regular-7';
    // span{
    //     font-family: 'Aleo-Bold-2';
    //     font-size: 14px;
    // }
    // .title{
    //     font-family: 'aleo-bold';
    // }
  }
}

.certContainer14 {
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 383px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;
  }
  .ziZm {
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm {
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 430px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }

  .award_team {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .award_team_out {
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .student_team_out {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school_team_out {
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_out_team {
    width: 297mm;
    position: absolute;
    top: 466px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .projectName {
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh {
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab {
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab {
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_rank {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_team {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .email {
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_rank {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .info_rank {
    position: absolute;
    top: 390px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
  }
  .school_team {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg {
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  red;
  }
}

.transcriptContainer1 {
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 440px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;
  }
  .ziZm {
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm {
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }

  .award_team {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .award_team_out {
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .student_team_out {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .school_team_out {
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_out_team {
    width: 297mm;
    position: absolute;
    top: 466px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .projectName {
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh {
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab {
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab {
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_rank {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .student_team {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
      text-align: center;
      margin-right: 0px;
      width: 297mm;
    }
  }
  .email {
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_rank {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .info_rank {
    position: absolute;
    top: 385px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.3;
  }
  .school_team {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg {
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  red;
  }
}
.transcriptContainer7{
  position: relative;
  margin-bottom:-4px;
  font-family: 'BellMT-Bold';
  .model_p{
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name{
    font-family: Bell-MT;
    position: absolute;
    top: 415px;
    font-size: 40px;
    font-weight: 600;
    width: 210mm;
    align-content: center;
    text-align: center;
  }
  .country{
    font-family: Bell-MT;
    position: absolute;
    top: 480px;
    font-size: 25px;
    font-weight: 600;
    width: 210mm;
    align-content: center;
    text-align: center;
  }

  .content{
    font-family: Bell-MT;
    position: absolute;
    top: 530px;
    font-size: 30px;
    left: 30mm;
    font-weight: 600;
    width: 150mm;
    align-content: center;
    text-align: center;
  }
  .fScore{
    color: black;
    position: absolute;
    top: 758px;
    font-size: 20px;
    left: 93mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .dcScore{
    color: black;
    position: absolute;
    top: 758px;
    font-size: 20px;
    left: 145mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;


  }
  .teamScore{
    font-family: Bell-MT;
    color: black;
    position: absolute;
    top: 458px;
    font-size: 20px;
    left: 39mm;
    font-weight: 600;
    // width: 150mm;
    align-content: center;
    text-align: center;

  }
  .info{
    color: black;
    position: absolute;
    top:384px;
    left: 38px;
    font-size: 14px;
    .tag{
      font-family: 'Aleo-Bold-2';
    }

  }
  .score{
    color: black;
    position: absolute;
    top: 500px;
    width:320px;

    left:55px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
  .score1{
    color: black;
    position: absolute;
    top: 500px;
    width:320px;
    left:434px;
    font-size: 12px;
    font-family: 'Aleo-Regular-7';
    span{
      font-family: 'Aleo-Bold-2';
      font-size: 14px;
    }
    .title{
      font-family: 'aleo-bold';
    }
  }
}
</style>

